<template>
  <div id="app">
    <div v-if="user" class="bg-gray-100 font-body flex flex-col h-screen">
      <warnning-message
        :visible="warning_message.visible"
        :message="warning_message.message"
        @hide="this.$store.dispatch('show_warning_message', false)"
      />
      <div class="flex flex-col flex-1 overflow-hidden">
        <Navbar
          style="
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -o-user-select: none;
          "
          :collapsed="collapsed"
          @collapse="this.$router.push('/dashboard')"
          @notifications="newsVisible = true"
        ></Navbar>

        <div class="flex flex-1 h-screen relative">
          <transition name="expandl">
            <SideBar
              class="
                min-h-screen
                overflow-y-auto
                pb-24
                z-10
                absolute
                md:static
                w-56
              "
              style="
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -o-user-select: none;
              "
              v-if="!collapsed && $i18n.locale == 'en'"
              @collapse="
                collapsed = true;
                updateSidebarStatus();
              "
            ></SideBar>
          </transition>
          <transition name="fadeslow">
            <ToastNotification
              class="cursor-pointer"
              :notification="notifications.success"
              @click="
                clear_interval(notifications.success.interval);
                notifications.success.visible = false;
              "
              @mouseover="clear_interval(notifications.success.interval)"
              @mouseleave="notifications.success.visible = false"
            />
          </transition>

          <div
            v-if="$i18n.locale == 'en'"
            class="
              m-4
              cursor-pointer
              text-ssr-side_bar-button_hover
              z-10
              rounded-full
              bg-ssr-side_bar-background
              absolute
              transform
              rotate-90
              hover:shadow-2xl
            "
            @click="
              this.collapsed = !this.collapsed;
              updateSidebarStatus();
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-9 w-9 inline-block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="5"
                d="M5 15l7-7 7 7"
              />
            </svg>
          </div>
          <div class="flex flex-1 flex-col overflow-y-auto mb-24 relative">
            <div
              class="w-full sticky top-0 z-10"
              :class="{ rtl: $i18n.locale == 'ar' }"
            >
              <div class="bg-red-400 flex" v-if="this.expiring.visible">
                <div
                  class="mx-24 font-bold font-sans cursor-pointer"
                  @click="this.expiring.visible = false"
                >
                  X
                </div>
                <div class="flex-1 text-center text-xl">
                  {{ this.expiring.message }}
                </div>
              </div>
              <div
                class="bg-yellow-400 flex"
                v-if="this.sessions_expiring.visible"
              >
                <div
                  class="mx-24 font-bold font-sans cursor-pointer"
                  @click="this.sessions_expiring.visible = false"
                >
                  X
                </div>
                <div class="flex-1 text-center text-xl">
                  {{ this.sessions_expiring.message }}
                </div>
              </div>
              <div
                class=" bg-ssr-side_bar-background flex py-2"
                v-if="this.update_check.visible"
              >
                <div
                  class="mx-24 font-bold font-sans cursor-pointer text-white"
                  @click="this.update_check.visible = false"
                >
                  X
                </div>
                <div
                  class="flex-1 flex justify-center text-center text-xl text-blue-400"
                >
                  <div
                    class="border-b-2 border-blue-400 cursor-pointer hover:text-blue-600 hover:border-blue-600 flex"
                    @click="
                      update_check.visible = false;
                      this.$router.push('/update');
                    "
                  >
                    <div>
                      {{ update_check.count }}
                    </div>
                    <div class="mx-4">
                      {{ $t("control.new_updates") }}
                    </div>
                    <div>
                      {{ $t("control.click_to_update") }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-red-500 flex" v-if="this.expired.visible">
                <div class="flex-1 text-center text-2xl py-4">
                  {{ this.expired.message }}
                </div>
              </div>
            </div>
            <div class="flex" v-if="newsVisible">
              <div
                class="bg-black border-white text-white border-2 w-9 h-9 rounded-full flex justify-center items-center m-2 z-20 absolute top-0 font-sans cursor-pointer"
                @click="newsVisible = false"
              >
                X
              </div>
              <NewsBar class="cursor-default" />
            </div>
            <router-view style="transition: all 0.25s ease"> </router-view>
          </div>
          <div
            v-if="$i18n.locale == 'ar'"
            class="
              m-4
              cursor-pointer
              text-ssr-side_bar-button_hover
              z-10
              rounded-full
              bg-ssr-side_bar-background
              absolute
              right-0
              transform
              -rotate-90
              hover:shadow-2xl
            "
            @click="
              this.collapsed = !this.collapsed;
              updateSidebarStatus();
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-9 w-9 inline-block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="5"
                d="M5 15l7-7 7 7"
              />
            </svg>
          </div>
          <transition name="expandr">
            <SideBar
              class="
                min-h-screen
                overflow-y-auto
                pb-24
                z-10
                absolute
                right-0
                md:static
                w-56
              "
              style="
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -o-user-select: none;
              "
              v-if="!collapsed && $i18n.locale == 'ar'"
              @collapse="
                collapsed = true;
                updateSidebarStatus();
              "
            ></SideBar>
          </transition>
        </div>
      </div>
    </div>
    <div v-if="!user">
      <Login v-if="showLogin" />
      <Home v-if="!showLogin" />
    </div>
  </div>
</template>

<script>
import "./styles/app.scss";
import "./styles/index.css";
import Navbar from "./components/Navbar.vue";
import SideBar from "./components/SideBar.vue";
import Login from "./views/Login.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Home from "./views/Home.vue";
import { POP_MESSAGE, TOGGLE_POP } from "./global_constants.js";
import WarnningMessage from "./components/modals/WarnningMessage.vue";
import ToastNotification from "./components/modals/ToastNotification.vue";
import NewsBar from "./components/sub_elements/NewsBar.vue";
export default {
  name: "app",
  components: {
    Navbar,
    SideBar,
    Login,
    Home,
    WarnningMessage,
    ToastNotification,
    NewsBar,
  },
  data() {
    return {
      showLogin: false,
      collapsed: false,
      newsVisible: true,
      expiring: {
        visible: false,
        message: "",
      },
      expired: {
        visible: false,
        message: "EXPIRED !!",
      },
      sessions_expiring: {
        visible: false,
        message: "",
      },
      update_check: {
        visible: false,
        change_log: "",
        name: "",
        url: "",
        count: 0,
      },
      notifications: {
        success: {
          visible: false,
          color: "#45cc45",
          message: "Inserted Successfully",
          interval: null,
        },
      },
    };
  },
  async beforeMount() {
    if (localStorage.getItem("server_api")) {
      axios.defaults.baseURL = localStorage.getItem("server_api");
      await this.updateValues();
    }
    if (!this.user) this.showLogin = true;
  },
  computed: {
    ...mapGetters([
      "user",
      "currentTime",
      "routeKey",
      "expiration_message",
      "warning_message",
    ]),
  },
  methods: {
    async updateValues() {
      try {
        const response = await axios.get("user");
        if (response.status == 200) {
          const responseSettings = await axios.get("settings");
          this.$store.dispatch("settings", responseSettings.data.data);
          this.$i18n.locale = responseSettings.data.data.language;
          this.$store.dispatch("user", response.data);
          if (!responseSettings.data.data.ssr_auth_connected) {
            this.$store.dispatch(
              "set_warning_message",
              this.$t("errors.license.auth_offline")
            );
            this.$store.dispatch("show_warning_message", true);
          }
          var expire_days = Math.abs(
            moment().diff(responseSettings.data.data.license.expires_at, "days")
          );
          if (
            responseSettings.data.data.license.expires_at != null &&
            expire_days < 31
          ) {
            this.expiring.visible = true;
            this.expiring.message = this.$t("errors.license.expiring", {
              days: expire_days,
            });
          }
          var remaining_percent =
            (responseSettings.data.data.sessions.online * 100) /
            responseSettings.data.data.sessions.available;
          if (
            remaining_percent >= 90 &&
            responseSettings.data.data.sessions.available > 0
          ) {
            this.sessions_expiring.visible = true;
            this.sessions_expiring.message = this.$t(
              "errors.license.expiring_sessions",
              { percent: remaining_percent }
            );
          }

          if (
            remaining_percent >= 99 &&
            responseSettings.data.data.sessions.available > 0
          ) {
            this.sessions_expiring.visible = false;
            this.expiring.visible = false;
            this.expired.visible = true;
            this.expired.message = this.$t("errors.license.expired_sessions");
          }
          if (
            responseSettings.data.data.license.expires_at != null &&
            moment()
              .add(-1, "days")
              .isAfter(responseSettings.data.data.license.expires_at)
          ) {
            this.sessions_expiring.visible = false;
            this.expiring.visible = false;
            this.expired.visible = true;
            this.expired.message = this.$t("errors.license.expired");
          }
        }
        if (localStorage.getItem("sidebar_status"))
          this.collapsed = localStorage.getItem("sidebar_status") == "true";
        const update_status = await axios.get("settings/update/check");
        if (update_status.status == 200) {
          if (update_status.data.update == "yes") {
            this.update_check.visible = true;
            this.update_check.name = update_status.data.data.name;
            this.update_check.url = update_status.data.data.url;
            this.update_check.change_log = update_status.data.data.change_log;
            this.update_check.count = update_status.data.count;
          }
        }
      } catch (error) {}
    },
    async updateSettings() {
      try {
        const responseSettings = await axios.get("settings");
        this.$store.dispatch("settings", responseSettings.data.data);
        if (!responseSettings.data.data.ssr_auth_connected) {
          this.$store.dispatch(
            "set_warning_message",
            this.$t("errors.license.auth_offline")
          );
          this.$store.dispatch("show_warning_message", true);
        }
      } catch (error) {}
    },
    updateSidebarStatus() {
      localStorage.setItem("sidebar_status", this.collapsed);
    },
    clear_interval(interval) {
      clearTimeout(interval);
      interval = null;
    },
  },
  watch: {
    $route(to, from) {
      this.updateSettings();
      if (
        (from.path == "/" || from.path == "/login") &&
        to.path == "/dashboard"
      )
        this.updateValues();

      if (this.$route.query.success) {
        this.notifications.success.visible = true;
        var message_flag = this.$route.query.success;
        var message = "تمت";
        switch (message_flag) {
          case "account_added":
            message = "تمت اضافة حساب بنجاح";
            break;
          default:
            message = "تمت العملية بنجاح";
            break;
        }
        this.notifications.success.message = message;
        this.notifications.success.interval = setTimeout(async () => {
          this.notifications.success.visible = false;
        }, 7000);
        this.$router.replace({ query: null });
      }
    },
  },
};
</script>
