export const ar = {
  navbar: {
    app_title: "SSR",
    messages: "الرسائل",
    notifications: "الإشعارات",
    empty_messages: "لا يوجد جديد حاليا",
    search: "بحث بواسطة: الاسم أو الحساب أو البطاقة أو البروفايل",
  },
  sidebar: {
    dashboard: "الرئيسية",
    accounts: {
      accounts: "الحسابات",
      subscribtions: "حسابات المستخدمين",
      online_sessions: "الجلسات المتصلة",
      add: "إضافة",
    },
    profiles: {
      profiles: "البروفايلات",
      all: "الكل",
      add: "إضافة",
    },
    cards: {
      cards: "البطاقات",
      groups: "مجموعات البطاقات",
      all: "جميع البطاقات",
      generate: "توليد مجموعة بطاقات",
    },
    admins: "المدراء",
    resellers: "الوكلاء",
    support_tickets: "طلبات الصيانة",
    notifications: {
      notifications: "الإشعارات",
      auto: "الإشعارات التلقائية",
      manual: "الإشعارات اليدوية",
      wa: "Whatsapp",
    },
    nas: "الشبكات",
    services: {
      services: "خدمات",
      mikrotik: "ميكروتيك",
      support_tickets: "طلبات الصيانة",
      bandwidth: "ضبط السرعة",
    },
    settings: {
      settings: "إعدادات",
      system_settings: "خيارات النظام",
      backup: "نسخ إحتياطي",
      restore: "إستيراد",
      update: "التحديثات",
      reset: "استعادة ضبط المصنع",
    },
    logout: "تسجيل خروج",
    admins_log: "سجل العمليات",
    accounting: "المحاسبة",
    bills: "فواتير",
    transactions: "مناقلات مالية",
    sessions_log: "سجل الاستهلاك",
    reports: "تقارير",
    admin_bills: "فواتير الوكلاء",
  },
  login: {
    title: "SUPER SPEED - Radius Manager",
    email: "اسم المستخدم",
    password: "كلمة المرور",
    realm: "Realm",
    login: "دخول",
    serial_number: "ادخل مفتاح الترخيص:",
    license: "تفعيل",
  },
  dashboard: {
    dashboard: "الرئيسية",
    statistics: {
      total_accounts: "إحمالي الحسابات",
      online_accounts: "الحسابات المتصلة",
      expired_accounts: "الحسابات المنتهية",
      disabled_accounts: "الحسابات المعطلة",
      total_cards: "إجمالي البطاقات",
      online_cards: "البطاقات المتصلة",
      expired_cards: "البطاقات المنتهية",
      disabled_cards: "البطاقات المعطلة",
      total_users: "إجمالي المستخدمين",
      profiles: "البروفايلات",
      managers: "المدراء",
      total_online: "الجلسات المتصلة",
      more: "عرض المزيد",
      money: "الرصيد المتوفر",
      expiring_accounts: "حسابات تنتهي قريبا",
      expiring_cards: "بطاقات تنتهي قريبا",
      support_tickets: "طلبات الصيانة",
      debt_accounts: "حسابات عليها ديون",
      expiration_profile_accounts: "حسابات ضمن بروفايل الانتهاء",
    },
    time: {
      uptime: "زمن التشغيل",
      version: "الإصدار",
    },
    gauges: {
      memory: "الذاكرة",
      cpu: "المعالج",
      disk: "القرص الصلب",
      uptime: "زمن التشغيل",
      voltage: "الفولت",
      cpu_temp: "حرارة المعالج",
    },
    daily: {
      daily_users: "مستخدم نشط يومياً",
    },
    logins: "تسجيل دخول",
    new_users: "مستخدم جديد",
  },
  accounts: {
    id: "رقم التعريف",
    account: "حساب",
    accounts: "الحسابات",
    auto_update: "تحديث تلقائي",
    add_account: "إضافة حساب",
    edit_account: "تعديل حساب",
    first_name: "الاسم",
    last_name: "الكنية",
    username: "اسم المستخدم",
    password: "كلمة المرور",
    email: "البريد الإلكتروني",
    phone: "رقم الجوال",
    phone_line: "رقم الهاتف الارضي",
    address: "العنوان",
    profile: "البروفايل",
    ip_address: "عنوان الايبي",
    expires_at: "ينتهي في",
    sessions: "عدد الاجهزة المسموحة للاتصال",
    used_quota: "الحزمة المستهلكة",
    status: "الحالة",
    connection: "الاتصال",
    notes: "ملاحظات",
    has_ip: "ايبي مثبت",
    has_ip_false: "ايبي تلقائي",
    has_expire_profile: "يوجد بروفايل نهاية خدمة",
    has_expire_profile_false: "لا يوجد بروفايل نهاية خدمة",
    has_fixed_expiration: "تاريخ الانتهاء",
    record_sessions: "حفظ جلسات الاتصال",
    record_sessions_false: "مسح جلسات الاتصال",
    expire_profile: "بروفايل نهاية الخدمة",
    auto_renew: "تجديد إشتراك تلقائي",
    auto_renew_false: "تجديد إشتراك يدوي",
    add: "إضافة",
    save: "حفظ",
    cancel: "إلغاء",
    details: "تفاصيل الحساب",
    general: "معلومات عامة",
    full_name: "الاسم الكامل",
    last_seen: "توقيت اخر اتصال",
    debts: "الديون",
    paid: "مدفوع",
    paid_false: "غير مدفوع",
    expired_since: "منته منذ",
    is_mac: "تثبيت تسجيل الدخول لماك واحد فقط",
    mac_address: "عنوان الماك المثبت",
    paid_at: "تاريخ دفع الفاتورة",
    next_profile: "البروفايل التالي عند انتهاء الاصلي",
    serial_number: "الرقم التسلسلي",
    wait_days: "مهلة الانتظار (ايام)",
    reseller: "الوكيل المسؤول عن الحساب",
    money: {
      title: "تعديل الرصيد",
      money: "الرصيد",
      current: "الرصيد الحالي",
      deposit: "إيداع",
      withdraw: "سحب",
    },
    temp_speed: {
      title: "تعديل سرعة حساب بشكل مؤقت",
      default_speed: "السرعة الافتراضية",
      new_speed: "السرعة الجديدة",
      note:
        "يتم تطبيق السرعة الجدية حتى ينتهي الوقت او يقوم الحساب بتسجيل الخروج ثم تعود السرعة الافتراضية",
      apply: "تطبيق",
      session_time: "وقت الجلسة (دقيقة)",
    },
    live_traffic: "عرض مخطط الاستهلاك",
    traffic: "مخطط استهلاك الحساب",
  },
  online: {
    online_customers: "الحسابات المتصلة",
    auto_update: "تحديث تلقائي",
    username: "إسم المستخدم",
    name: "الاسم",
    profile: "البروفايل",
    mac_address: "العنوان الفيزيائي",
    ip: "الايبي",
    type: "النوع",
    traffic_used: "الاستهلاك",
    uptime: "مدة الاتصال",
    nas_name: "شبكة الاتصال",
    interface: "الانترفيس",
    from: "المصدر",
    start_date: "توقيت البدء",
    stop_date: "توقيت التوقف",
    stop_cause: "سبب التوقف",
    sum: "مجموع الاستهلاك",
    range_date: "تحديد فترة زمنية",
    truncate: {
      title: "تنظيف الجلسات القديمة",
      end_date: "مسح الجلسات قبل تاريخ محدد",
      only: "مسح الجسات لـ:",
      all: "حسابات وبطاقات",
      cards_only: "بطاقات فقط",
      accounts_only: "حسابات فقط",
      warning_cards:
        "تحذير! مسح الجلسات للبطاقات الغير منتهية يتسبب في اعادة تمكين استخدامها.",
      truncate: "تأكيد المسح",
      confirm: "هي انت متأكد من مسح الجلسات",
      before_3months: "يجب ان يكون التاريخ قبل 3 اشهر على الاقل",
    },
  },
  profiles: {
    profile: "البروفايل",
    auto_update: "تحديث تلقائي",
    name: "الاسم",
    enabled: "مفعل",
    disabled: "معطل",
    service: "الخدمة",
    type: "النوع",
    price: "السعر",
    speed_limit: "السرعة",
    expiration: "الانتهاء",
    quota: "الاستهلاك المسموح",
    ip_pool: "Mikrotik IP Pool",
    total_accounts: "عدد الحسابات",
    online_accounts: "الحسابات المتصلة",
    allowed_nases: "الشبكات المسموحة",
    add_profile: "إضافة بروفايل",
    edit_profile: "تعديل بروفايل",
    connection_speed: "سرعة الاتصال:",
    download_speed: "سرعة التحميل",
    upload_speed: "سرعة الرفع",
    expiration_date: "تاريخ إنتهاء محدد",
    expiration_date_false: "تاريخ إنتهاء غير محدد",
    limited_quota: "استهلاك محدد",
    limited_quota_false: "استهلاك غير محدود",
    shared: "بروفايل تشاركي",
    shared_false: "بروفايل غير تشاركي",
    shared_note:
      "تفعيل هذا الخيار يضيف سكريبت تشاركي على الميكروتيك بشكل تلقائي",
    shared_download_speed: "التحميل التشاركي",
    shared_upload_speed: "الرفع التشاركي",
    custom_attributes: "سمات مخصصة",
    resellers_allowed_sessions: "(خاص بالوكلاء) عدد الأجهزة المسموحة",
    open_speed: "فتح سرعة البروفايل",
    start_hour: "وقت البدء",
    stop_hour: "وقت التوقف",
    open_download: "سرعة التحميل المفتوحة (ميغا)",
    open_upload: "سرعة الرفع المفتوحة (ميغا)",
    select_nas: "اختر شبكة",
    unlimited_speed: "سرعة غير محدودة",
    unlimited_speed_false: "سرعة محدودة",
    expiration_profile: "بروفايل انتهاء الخدمة",
    expiration_profile_note:
      "تفعيل هذا الخيار يضيف مجال ايبي على الميكروتيك بشكل تلقائي ويقوم بعرض صفحة انتهاء الاشتراك عند انتهاء اشتراك الحساب",
    expiration_profile_message: "رسالة الانتهاء",
    next_profile: "البروفايل التالي",
    unit: {
      unit: "الواحدة",
      days: "أيام",
      months: "أشهر",
      hours: "ساعات",
    },
    add: "إضافة",
    save: "حفظ",
    cancel: "إلغاء",
    renew: {
      title: "تجديد إشتراك حساب",
      accounts_title: "تثبيت تاريخ انتهاء حسابات البروفايل",
      amount: "عدد مرات الإضافة",
      renew: "تجديد",
      confirm: "هل انت متأكد من تجديد الحساب حتى التاريخ المحدد أعلاه ؟",
      accounts_confirm:
        "هل انت متأكد من تجديد جميع حسابات البروفايل حتى التاريخ المحدد أعلاه ؟",
      money_state: "حالة الدفع",
      paid: "مدفوع",
      in_debt: "غير مدفوع",
      debt_confirm: "بدون دفع التكلفة؟",
      paid_debt_confirm: "بدون دفع كامل التكلفة؟",
    },
    app_allowed: "مسموح في التطبيق",
    app_allowed_false: "غير مسموح في التطبيق",
    app_allowed_note:
      "مسموح للاستخدام في التطبيق ليقوم المستخدم بالتجديد يدويا عن طريق الرصيد",
    change_profile_values: {
      details: "تفاصيل تغيير البروفايل",
      old_price: "سعر البروفايل الحالي:",
      new_price: "سعر البروفايل الجديد:",
      billed_days: "الايام المتبقية لانتهاء البروفايل الحالي:",
      new_days: "الايام الجديدة المضافة:",
      billed_days_price: "تكلفة تغيير بروفايل الايام المتبقية:",
      new_days_price: "تكلفة الايام الجديدة المضافة:",
    },
  },
  batches: {
    auto_update: "تحديث تلقائي",
    add_cards: "إضافة بطاقات",
    name: "الاسم",
    quantity: "الكمية",
    price: "السعر",
    prefix: "السابقة",
    profile: "البروفايل",
    username_length: "طول اسم المستخدم",
    password_length: "طول كلمة المرور",
    sessions: "عدد الاجهزة",
    created_at: "تاريخ الإنشاء",
    chars: "نمط المحارف",
    alpha: "أحرف وأرقام",
    numeric: "أرقام فقط",
    add: "توليد",
    save: "حفظ",
    cancel: "إلغاء",
    print: "طباعة",
    print_cards: "طباعة بطاقات",
    logo: "شعار الشبكة",
    network: "اسم الشبكة",
    horiz: "الوضع الافقي",
    cards_count: "عدد البطاقات",
    cards_count_row: "عدد البطاقات في كل سطر",
    speed: "السرعة",
    duration: "المدة",
    quota: "الإستهلاك",
    color_border: "الإطار",
    color_background: "الخلفية",
    color_text_big: "النص الاساسي",
    color_text_small: "النص الثانوي",
    color_input: "المربعات الاساسية",
    color_boxes: "المربعات الثانوية",
    add_background: "إضافة خلفية",
    username_password: "اسم المستخدم وكلمة المرور",
    truncate: "حذف جميع البطاقات المنتهية",
    designer: "نمط التصميم",
    basic_designer: "بسيط",
    advanced_designer: "متقدم",
    saved_designs: "التصميمات المحفوظة",
    design_name: "اسم التصميم",
    new_design: "تصميم جديد",
    resize: "التصغير من هنا",
    fresh_cards: "بطاقات غير مستخدمة",
    is_mac: "تسجيل الدخول لماك واحد فقط",
  },
  cards: {
    card: "بطاقة",
    auto_update: "تحديث تلقائي",
    username: "اسم المستخدم",
    password: "كلمة المرور",
    used: "مستعمل",
    profile: "البروفايل",
    batch: "الحزمة",
    expires_at: "تاريخ الإنتهاء",
    used_quota: "الحزمة المستهلكة",
    status: "الحالة",
    connection: "الإتصال",
    details: "تفاصيل البطاقة",
  },
  admins: {
    auto_update: "تحديث تلقائي",
    add_admins: "إضافة مدراء",
    edit_admins: "تعديل الحساب",
    first_name: "الاسم",
    last_name: "الكنية",
    email: "اسم المستخدم",
    password: "كلمة المرور",
    phone: "الهاتف",
    privileges: "الأذونات",
    allowed_nases: "الشبكات المسموحة",
    allowed_profiles: "البروفايلات المسموحة",
    change: "تغيير",
    cancel: "إلغاء",
    save: "حفظ",
    add: "إضافة",
    enable_all: "تفعيل الكل",
    disable_all: "إلغاء الكل",
    add_resellers: "إضافة وكلاء",
    money: "الرصيد",
    prefix: "السابقة",
    type: "النوع",
    custom_network_logo: "شعار شبكة مخصص",
    custom_network_name: "اسم شبكة مخصص",
    signature: "التوقيع",
  },
  nases: {
    auto_update: "تحديث تلقائي",
    add_device: "إضافة جهاز",
    edit_device: "تعديل جهاز",
    name: "الاسم",
    ip: "الايبي",
    secret: "Secret",
    username: "اسم المستخدم",
    password: "كلمة المرور",
    api_port: "بورت الدخول",
    api_username: " اسم المستخدم",
    api_password: " كلمة المرور",
    snmp_community: "SNMP Community",
    radius_secret: "Radius Secret",
    community: "Community",
    allowed_profiles: "البروفايلات المسموحة",
    allowed_admins: "مرئي للمدراء",
    connection: "معلومات الاتصال",
    login: "الربط مع ميكروتيك",
    other: "معلومات إضافية",
    privileges: "الصلاحيات",
    version: "الإصدار",
    status: "الحالة",
    api: "API",
    cancel: "إلغاء",
    save: "حفظ",
    add: "إضافة",
    settings: "الاعدادات",
    online_sessions: "الجلسات المتصلة",
  },
  settings: {
    change_settings: "تغيير الإعدادات",
    change_logo: "تغيير الشعار",
    network_name: "اسم الشبكة",
    time_zone: "المنطقة الزمنية",
    currency: "العملة",
    default_language: "اللغة الإفتراضية",
    cancel: "إلغاء",
    save: "حفظ",
    add: "إضافة",
    apply: "تطبيق",
    applied: "تم التطبيق",
    auto_backup: "نسخ احتياطي تلقائي",
    default_expiration_hour: "التوقيت الافتراضي لانتهاء الحسابات",
    system: "اعدادات النظام",
    ssra: "اعدادات تطبيق الجوال",
    ssr_account_password_change: "يمكن للحسابات تغيير كلمة المرور",
    ssr_account_profile_update:
      "يمكن للحسابات تجديد البروفايل باستخدام الرصيد المتاح",
    reset: {
      reset_db_info: "اعادة ضبظ قواعدالبيانات إلى الاعدادت الافتراضية",
      reset_db_confirm:
        "هل انت متأكد من إعادة ضبط قواعد البيانات؟ سوف يتم مسح جميع البيانات بما فيها الحسابات والبطاقات وسجلات الاستهلاك والعودة إلى بيانات تسجيل الدخول الافتراضية",
      resetting_db: "جار اعادة ضبط قواعد البيانات...",
      reset: "اعادة ضبط",
    },
    phone_number: "رقم التواصل",
    status_url: "رابط صفحة الرصيد",
    nas: "اعدادت Mikrotik",
    nas_interim_update: "زمن تبادل قيم الاستهلاك مع راديوس",
    resellers: "اعدادت الوكلاء",
    benefit_rate: "نسبة الربح %",
  },
  mikrotik: {
    services: "خدمات ميكروتيك",
    auto_reboot: "إعادة الإقلاع يومياً عند 5 صباحا",
    auto_reboot_false: "إعادة الإقلاع التلقائية معطلة",
    whatsapp_messages_only: "رسائل واتساب مجانية فقط",
    whatsapp_messages_only_false: "رسائل واتساب مجانية فقط معطلة ",
    whatsapp_full: "واتساب مجاني كامل",
    whatsapp_full_false: "واتساب مجاني كامل معطل",
    dns: "حماية من الفيروسات والموافع الإباحية فعالة",
    dns_false: "حماية من الفيروسات والموافع الإباحية معطلة ",
    open_speed: "فتح جميع السرعات في أوقات محددة",
    open_speed_false: "فتح جميع السرعات في أوقات محددة",
  },
  support_tickets: {
    id: "رقم الطلب",
    username: "اسم المستخدم",
    issue: "المشكلة",
    phone: "الهاتف",
    address: "العنوان",
    status: "الحالة",
    notes: "ملاحظات إضافية",
    ssr_notes: "SSR تعليق",
    add_ticket: "إضافة طلب",
    new: "جديد",
    in_progress: "جار المعالجة",
    resolved: "تم",
    failed: "فشل",
    priority: {
      priority: "الاولوية",
      low: "منخفصة",
      normal: "متوسطة",
      high: "عالية",
    },
    today: "اليوم",
  },
  notifications: {
    title: "العنوان",
    body: "نص الإشعار",
    receiver: "المستقبل",
    sender: "المرسل",
    image: "الصورة",
    status: "الحالة",
    created_at: "تاريخ الارسال",
    show_archived: "عرض المؤرشفة",
    new_notification: "إشعار جديد",
    archived: "مؤرشف",
    new: "جديد",
    error: "لايمكن ترك خانة فارغة",
    push: "إرسال الإشعار",
    confirm: "هل انت متأكد من إرسال الإشعار إلى الوجهة المحددة؟",
    type: {
      type: "النوع",
      account_expiring: "قرب انتهاء حساب",
      account_expired: "انتهاء حساب",
      account_renew: "تجديد حساب",
      account: "إلى حساب",
      profile: "إلى بروفايل",
      nas: "إلى شبكة",
      ssr: "إلى الكل",
      global: "من النظام",
      support_new: "طلب صيانة جديد",
      support_in_progress: "جار معالجة طلب الصيانة",
      support_resolved: "تم حل طلب الصيانة",
      support_failed: "فشل طلب الصيانة",
      whatsapp: "رسائل واتساب",
    },
    wa: {
      status: "حالة الاتصال",
      statuses: {
        authenticated: "تم الاتصال",
        qr: "بانتظار مسح الرمز",
        initialize: "تجهيز الاتصال...",
        session_not_connected: "انتظار الاتصال...",
        session_connected: "متصل",
        session_not_found: "رمز الترخيص خاطئ",
        no_instance: "بانتظار مفتاح الترخيص ",
        free: "متصل باستخدام الخدمة المجانية",
      },
      subscription_key: "مفتاح الترخيص",
      validate: "ارسال المفتاح",
      scan_qr: "قم بمسح الكود باستخدام تطبيق واتساب وميزة ربط جهاز",
      refresh: "تحديث",
    },
    config: {
      expiring_enabled: "تفعيل إشعارات اقتراب انتهاء الحساب",
      expired_enabled: "تفعيل إشعارات انتهاء الحساب",
      renew_enabled: "تفعيل إشعارات تجديد الحساب",
      expiring_body: "رسالة اقتراب انتهاء الحساب",
      expired_body: "رسالة إنتهاء الحساب",
      renew_body: "رسالة تجديد الحساب",
    },
  },
  admins_log: {
    id: "الرقم التسلسلي",
    user: "اسم المستخدم",
    operation: "العملية",
    details: "التفاصيل",
    created_at: "التاريخ",
  },
  transactions: {
    id: "الرقم التسلسلي",
    username: "المستلم",
    account_username: "اسم الاشتراك",
    type: "النوع",
    amount: "المبلغ",
    subject: "الموضوع",
    notes: "ملاحظات",
    withdraw: "سحب",
    deposit: "دفع",
    paid_amount: "المبلغ المدفوع",
    required_amount: "المبلغ المطلوب",
    remaining: "باقي عليه دفع",
    pay: "دفع",
    discount: "الخصم",
  },
  bills: {
    slug: "الرمز",
    price: "السعر",
    paid: "المدفوع",
    from: "من",
    remaining: "المتبقي",
    to: "إلى",
    notes: "ملاحظات",
    pay: "تسديد الفاتورة",
    pay_confirm: "هل انت متأكد من تسديد الفاتورة بالمبلغ المحدد؟",
    total: {
      total_grand: "المجموع الكلي",
      total_paid: "مجموع المدفوعات",
      total_debt: "مجموع الديون",
      current_grand: "مجموع الصفحة الحالية",
      current_paid: "مدفوعات الصفحة الحالية",
      current_debt: "ديون الصفحة الحالية",
    },
    invoice: {
      bill_to: "فاتورة للسيد",
      issued_date: "تاريخ الإصدار",
      account_id: "رقم الحساب",
      bill_id: "رقم الفاتورة",
      profile: "اسم الاشتراك",
      description: "تفاصيل",
      amount: "المبلغ",
      remaining: "المتبقي",
      issued_by: "تم الإصدار بواسطة",
    },
    invoice_number: "رقم الفاتورة",
    admin: "المدير",
    invoice_at: "تاريخ الفاتورة",
    created_at: "تاريخ الدفع",
  },
  license: {
    license: "الترخيص",
    realm: "الريلم",
    online: "الجلسات المتصلة",
    remaining: "الجلسات المتبقية",
    serial_number: "الرقم السري",
    id: "معرف الجهاز",
    ip: "IP",
    device: "الجهاز",
    subscription: "الاشتراك",
    full_name: "الاسم الكامل",
    name: "الاسم",
    network: "الشبكة",
    expires_at: "تاريخ الانتهاء",
    package: "الخطة",
    package_name: "الاسم",
    package_details: "التفاصيل",
    package_months: "(اشهر) المدة",
    package_online_count: "الجلسات المتصلة المسموحة",
    package_nases_count: "عدد الشبكات المسموح",
    package_admins_count: "عدد المدراء المسموح",
    package_resellers_count: "عدد الوكلاء المسموح",
    update: "تحديث الرخصة",
    update_error: "فشل تحديث الرخصة",
    update_success: "تم تحديث الرخصة بنجاح",
    phone: "رقم الهاتف",
    email: "البريد الالكتروني",
  },
  restore: {
    choose_file: "إختيار ملف",
    import: "إستيراد",
    import_dma: "DMA إستيراد ملف من",
    import_local: "إستيراد نسخة احتياطية SSR",
    file_ready: "الملف جاهز",
    restore_radacct: "(radacct) إستعادة الجلسات المستهلكة",
    restore_radacct_false: "(radacct) عدم إستعادة الجلسات المستهلكة",
    warning_radacct:
      "تحذير ! قد تستغرق العملية وقتاً يصل إلى 30 دقيقة حسب حجم فاعدة البيانات",
    clean_restore: "مسح البيانات السابقة",
    clean_restore_false: "الاحتفاظ بالبيانات السابقة",
    import_nas: "استعادة البيانات المستخرجة من شبكة SSR",
    warning_nas:
      "تحذير! استعادة بيانات الشبكة تسبب مسح كامل قاعدة البيانات السابقة",
  },
  backup: {
    file_name: "الاسم",
    action: "العملية",
    download: "تحميل",
    delete: "حذف",
    now: "حفظ نسخة الان",
  },
  iptv: {
    logo: "شعار القناة",
    name: "اسم القناة",
    url: "رابط بث القناة",
    add: "اضافة قناة",
  },
  updates: {
    name: "اسم التحديث",
    change_log: "التغييرات",
    download_install: "تحميل وتثبيت",
    downloading_message: "جار التحميل...",
    downloading_fail: "فشل التحميل",
    installing_message: "جار التثبيت",
    installing_fail: "فشل التثبيت",
  },
  reports: {
    yearly: "سنوي",
    monthly: "شهري",
    daily: "يومي",
    duration: "مدة محددة",
    year: "سنة",
    month: "شهر",
    day: "يوم",
    hour: "ساعة",
    from: "من",
    to: "إلى",
    generate: "توليد",
    finance_report: "تقرير مالي",
    finance: "تقرير مالي",
    sells_report: "تقرير مبيعات",
    sells: "تقرير مبيعات",
    amount: "المبلغ",
    accounts: "الحسابات",
    cards: "البطاقات",
    profile: "البروفايل",
    accounts_count: "عدد الحسابات",
    batch: "المجموعة",
    cards_count: "عدد البطاقات",
    total: "الاجمالي",
  },
  bandwidth: {
    title: "ضبط السرعة",
    total_use: "كل البروفايلات",
    total_peak: "الاستخدام الاقصى لجميع الحسابات المتصلة",
    total_average: "الاستخدام المتوسط لجميع الحسابات المتصلة",
    reset: "اعادة ضبط افتراضي",
    update: "حفظ وتحديث الكل",
    online_accounts: "حساب متصل",
    online_peak_band: "الاستخدام الاقصى للحسابات المتصلة",
  },
  actions: {
    view: "عرض",
    edit: "تعديل",
    renew: "تجديد أو تغيير الإشتراك",
    clear: "مسح الجلسات",
    disconnect: "قطع الإتصال",
    enable: "تفعيل",
    disable: "تعطيل",
    delete: "حذف",
    view_profile: "عرض البروفايل",
    print: "طباعة",
    archive: "أرشفة",
    export_csv: " CSV تصدير",
    notify: "إشعار للتطبيق",
    view_sessions: "عرض الجلسات",
    edit_popup: "تعديل في نافذة جديدة",
    view_bills: "عرض الفواتير",
    renew_48: "مهلة يومين",
    renew_48_free: "مجاني يومين",
    edit_money: "تعديل الرصيد",
    export_nas: "استخراج بيانات الشبكة",
    temp_speed: "تعديل مؤقت للسرعة",
  },
  control: {
    rows_10: "10 أسطر",
    rows_50: "50 أسطر",
    rows_100: "100 أسطر",
    rows_500: "500 أسطر",
    rows_all: "جميع الاسطر",
    yes: "نعم",
    no: "لا",
    back: "رجوع",
    of: "من",
    columns: "الأعمدة المرئية",
    filter: "بحث",
    enabled: "مفعل",
    any: "الكل",
    hotspot: "Hotspot",
    ppp: "PPP",
    subscribtion: "إشتراك",
    prepaid: "بطاقات",
    online: "متصل",
    offline: "غير متصل",
    all: "الكل",
    active: "مفعل",
    expired: "منتهي الصلاحية",
    seconds: "ثواني",
    minutes: "دقائق",
    hours: "ساعات",
    unlimited: "غير محدود",
    details: "التفاصيل",
    created_at: "تاريخ الانشاء",
    updated_at: "اخر تعديل",
    allowed_quota: "الاستهلاك المسموح",
    remaining_quota: "الاستهلاك المتبقي",
    delete: "حذف",
    cancel: "إلغاء",
    total: "العدد الكلي:",
    from: "من",
    to: "إلى",
    disabled: "معطل",
    auto: "تلقائي",
    device: "جهاز",
    cost: (ctx) => `سوف تكلفك العملية: (${ctx.named("cost")})`,
    money_left: (ctx) =>
      `الرصيد المتبقي (${ctx.named("original")} => ${ctx.named("left")})`,
    profile_cost: (ctx) => `سعر البروفايل: ${ctx.named("price")}`,
    benefit_cost: (ctx) => `الربح: ${ctx.named("price")}`,
    expiring: "على وشك الانتهاء",
    hours_24: "24 ساعة",
    months_1: "1 شهر",
    months_6: "6 أشهر",
    years_1: "1 سنة",
    fetch_failed: "تعذر",
    active_sessions: "عدد الاجهزة المتصلة",
    commit: "تطبيق الاعدادات",
    commited: "تم التطبيق",
    warning: "تحذير",
    save_filters: "حفظ الفلاتر",
    sum: "المجموع",
    clear: "مسح",
    save: "حفظ",
    new_updates: "تحديث جديد",
    click_to_update: "انقر للتحديث",
    default: "الافتراضي",
    ok: "تم",
    benefit: "الربح",
  },
  operations: {
    account_create: "حسابات: انشاء",
    account_create_cost: "حسابات: تكلفة الانشاء",
    account_expiration: "حسابات: تعديل تاريخ انتهاء",
    account_password: "حسابات: تعديل كلمة مرور",
    account_sessions: "حسابات: مسح الجلسات",
    account_username: "حسابات: تعديل اسم المستخدم",
    account_allowed_sessions: "حسابات: تعديل الاجهزة المسموحة",
    account_update: "حسابات: تعديل",
    account_delete: "حسابات: حذف",
    account_disconnect: "حسابات: قطع اتصال",
    account_enable: "حسابات: تفعيل",
    account_disable: "حسابات: تعطيل",
    account_renew: "حسابات: تجديد اشتراك",
    account_renew_cost: "حسابات: تكلفة التجديد",
    account_renew48: "حسابات: مهلة 2 يوم",
    account_renew48_free: "حسابات: تجديد 2 يوم",
    admins_add: "مدراء: انشاء",
    admins_update: "مدراء: تعديل",
    admins_delete: "مدراء: حذف",
    admins_password: "مدراء: تعديل كلمة المرور",
    resellers_add: "وكلاء: انشاء",
    resellers_money: "وكلاء: تحديث الرصيد",
    card_design_save: "بطاقات: حفظ التصميم",
    cards_generate: "بطاقات: توليد بطاقات",
    cards_generate_cost: "بطاقات: تكلفة توليد البطاقات",
    card_password: "بطاقات: تعديل كلمة مرور",
    card_expiration: "بطاقات: تعديل تاريخ انتهاء",
    card_allowed_sessions: "بطاقات: تعديل الاجهزة المسموحة",
    card_edit: "بطاقات: تعديل",
    card_delete: "بطاقات: حذف بطاقة",
    cards_delete: "بطاقات: حذف مجموعة",
    card_clear_sessions: "بطاقات: مسح جلسات",
    card_disconnect: "بطاقات: قطع اتصال",
    card_enable: "بطاقات: تفعيل",
    card_disable: "بطاقات: تعطيل",
    session_disconnect: "جلسات: قطع اتصال",
    mikrotik_services_update: "خدمات: تعديل خدمات ميكروتيك",
    nas_create: "شبكات: انشاء",
    nas_update: "شبكات: تحديث",
    nas_delete: "شبكات: حذف",
    nas_api_commit: "شبكات: تطبيق الاعدادات",
    notify_account: "اشعارات: إرسال الى حساب",
    notify_card: "اشعارات: ارسال الى بطاقة",
    notify_profile: "اشعارات: ارسال الى حسابات بروفايل",
    notify_nas: "اشعارات: ارسال الى حسابات شبكة",
    notify_ssr: "اشعارات: ارسال الى حسابات مدير",
    notifications_archive: "اشعارات: ارشفة",
    profiles_create: "بروفايلات: انشاء",
    profile_update: "بروفايلات: تعديل",
    profile_delete: "بروفايلات: حذف",
    profile_disable: "بروفايلات: تعطيل",
    profile_enabled: "بروفايلات: تفعيل",
    profiles_delete: "بروفايلات: حذف",
    support_ticket_delete: "طلبات الصيانة: حذف",
    support_ticket_update: "طلبات الصيانة: تعديل",
    support_ticket_create: "طلبات الصيانة: انشاء",
    settings_update: "اعدادت النظام: تعديل",
    database_backup: "اعدادت النظام: نسخ احتياطي",
    database_backup_auto: "اعدادات النظام: نسخ احتياطي تلقائي",
    admin_login: "تسجيل دخول: مدير",
    reseller_login: "تسجيل دخول: وكيل",
    reseller_benefit: "وكلاء: ارباح",
    reseller_money_withdraw: "وكلاء: سحب رصيد",
    reseller_money_deposit: "وكلاء: إضافة رصيد",
    reseller_bill_delete: "وكلاء: حذف فاتورة",
    reseller_benefit_delete: "وكلاء: سحب ربح",
    bill_pay: "فواتير: دفع فاتورة",
    bill_delete: "فواتير: حذف فاتورة",
  },
  currencies: {
    usd: "دولار امريكي $",
    tr: "ليرة تركية ₺",
    syp: "ليرة سورية SYP",
    iqd: "دينار عراقي IQD",
    jd: "دينار اردني JD",
    lbp: "ليرة لبنانية LBP",
    egp: "جنيه مصري EGP",
    sar: "ريال سعودي SAR",
  },
  errors: {
    accounts: {
      username_empty: "لا يمكن ان يكون اسم المستخدم فارغاً",
      username_2: "يجب ان يحتوي اسم المستخدم على حرفين أو أكثر",
      username_symbol: "لايمكن ان يحتوي اسم المستخدم على رموز",
      username_taken: "اسم المستخدم محجوز مسبقاً",
      invalid_ip: "ايبي خاطئ",
      password_empty: "لا يمكن ان تكون كلمة المرور فارغة",
      password_2: "يجب ان يحتوي كلمة المرور على حرفين أو أكثر",
      select_profile: "يرجى تحديد البروفايل",
      sessions_warning:
        "تنبيه: يؤدي عدم حفظ الجلسات إلى فقدان كمية استهلاك الحزمة",
      serial_number_taken: "الرقم التسلسلي مستخدم مسبقا",
    },
    batches: {
      name_empty: "الرجاء ادخال اسم المجموعة",
      name_taken: "اسم المجموعة مستخدم مسبقاً",
      username_length_6: "يجب ان يحوي اسم المستخدم على 6 محارف أو اكثر",
      password_length_3: "يجب ان تحوي كلمة المرور على 3 محارف أو اكثر",
      chars: "عدد محارف اسم المستخدم غير كافي لتوليد هذا العدد من البطاقات",
      truncate_failed: "فشل الحذف",
      not_enough:
        "تعذر توليد الكمية المطلوبة باستخدام 3 محارف لاسم المستخدم، الرجاء حذف البطاقات القديمة وافراغ المساحة أو زيادة عدد المحارف",
    },
    profiles: {
      name_empty: "يرجو ادخال الاسم",
      name_taken: "الاسم مستخدم مسبقاً",
      nases_1: "الرجاء اختيار شبكة 1 على الاقل",
      speed_0: "يرجى تحديد السرعة",
    },
    admins: {
      first_name: "الاسم إجباري",
      last_name: "الكنية إجبارية",
      username_3: "يجب ان يحتوي اسم المستخدم على 3 أحرف على الأقل",
      username_taken: "اسم المستخدم مستعمل مسبقاً",
      password_3: "يجب ان تحتوي كلمة المرور على 3 أحرف على الأقل",
    },
    restore: {
      uploading: "فشل الرفع !!",
      parsing: "فشلت المعالجة !!",
      cleaning: "فشل المسح !!",
      server: "حدث خطأ في المخدم يرجى إعادة المحاولة",
    },
    notifications: {
      empty: "الإشعار لا يمكن ان يكون فارغ",
      wa: {
        not_found: "المفتاح غير صحيح",
        already_used: "المفتاح مستخدم مسبقاً",
        url: "لايمكن ان تحتوي الرسالة على روابط",
      },
    },
    support_tickets: {
      account_id: "قم باختيار الحساب",
      issue: "لايمكن ترك المشكلة فارغة",
    },
    license: {
      expiring: (ctx) =>
        `تحذير: ينتهي اشتراكك في خدمة SSR بعد ${ctx.named(
          "days"
        )} ايام، قم بتجديد الاشتراك كي لا تفقد التحكم`,
      expiring_sessions: (ctx) =>
        `تحذير: قمت باستهلاك ${ctx.named(
          "percent"
        )}% من الجلسات المتصلة المسموح بها، قم بزيادة الاشتراك كي لا يفقد المشتركين القدرة على تسجيل الدخول`,
      expired:
        "انتهاء الاشتراك: لقد انتهت المدة المحددة للاشتراك الخاص بك، قم بتجديد الاشتراك كي تستعيد الوصول",
      expired_sessions:
        "انتهاء الجلسات: لقد انتهت الجلسات المسوحة للاتصال لن تتمكن الحسابات من الاتصال حتى تقوم بزيادة الاشتراك",
      auth_offline:
        "فشل التحقق من الترخيص! تأكد من الاتصال بالانترنت، بعض الميزات لن تعمل حتى اتمام التحقق",
      privileges: "لاتمتلك الصلاحيات المطلوبة!",
      license_nases_exceeded: "لا يمكن تجاوز عدد الشبكات المسموح في الرخصة",
      license_admins_exceeded: "لا يمكن تجاوز عدد المدراء المسموح في الرخصة",
      license_resellers_exceeded: "لا يمكن تجاوز عدد الوكلاء المسموح في الرخصة",
    },
    iptv: {
      name_empty: "لايمكن ترك اسم القناة فارغاً",
      url_empty: "لا يمكن ترك رابط القناة فارغاً",
    },
  },
  messages: {
    delete_confirm: "هل انت متأكد من الحذف ؟",
    accounts: {
      delete_all:
        "هل انت متأكد من حذف الحسابات المحددة ؟ لايمكنك التراجع عن هذه العملية",
      clear_all:
        "هل انت متأكد من حذف جلسات الحسابات المحددة ؟ لايمكنك التراجع عن هذه العملية",
      disable: "هل انت متأكد من تعطيل الحساب",
      disconnect: "هل انت متأكد من قطع اتصال الحساب ؟",
      clear: "هل انت متأكد من مسح اسهتلاك الحزمة لهذا الحساب ؟",
      enable: "هل انت متأكد من تفعيل الحساب",
      auto_mac:
        "اترك هذا الحقل فارغاً ليقوم النظام بتثبيت عنوان اول جهاز تلقائيا",
      not_expiring_soon: "ينتهي هذا الحساب بعد أكثر من 10 أيام",
      warning: "تنبه",
      temp_renew: "هل انت متأكد من اضافة مهلة 2 يوم للحساب للدفع ؟",
      temp_renew_free:
        "هل انت متأكد من اضافة 2 يوم لتاريخ انتهاء الحساب بشكل مجاني؟",
    },
    cards: {
      delete_all:
        "هل انت متأكد من حذف الحسابات المحددة ؟ لايمكنك التراجع عن هذه العملية",
      disable: "هل انت متأكد من تعطيل البطاقة المحددة ؟",
      disconnect: "هل انت متأكد من قطع اتصال البطاقة المحددة",
      clear: "هل انت متأكد من مسح اسهتلاك الحزمة لهذه البطاقة ؟",
      enable: "هل انت متأكد من تفعيل البطاقة المحددة ؟",
      truncate: "هل انت متأكد من حذف جميع البطاقات المنتهية؟",
    },
    batches: {
      is_mac: "السماح لعنوان ماك الاول فقط باستخدام البطاقة",
    },
    profiles: {
      delete_all:
        "هل انت متأكد من حذف البروفايلات المحددة وجميع الحسابات والبطاقات المرتبطة بها ؟ لايمكنك التراجع عن هذه العملية",
      disable_all:
        "هل انت متأكد من تعطيل إضافة حسابات جديدة بواسطة هذا البروفايل ؟",
      open:
        "لن تعمل فتح السرعات ان قمت باستخدام خيار البروفايل التشاركي او السمات المخصصة",
    },
    admins: {
      delete_all:
        "هل انت متأكد من حذف المدراء المحددين ؟ لايمكنك التراجع عن هذه العملية",
    },
    restore: {
      uploading: "10% رفع البيانات",
      parsing: "40% معالجة البيانات",
      cleaning: "90% مسح التخزين المؤقت",
    },
    support_tickets: {
      delete_all:
        "هل انت متأكد من حذف الطلبات المحددة ؟ لايمكنك التراجع عن هذه العملية",
    },
    notifications: {
      archive: "هل انت متأكد من ارشفة وإخفاء الإشعار المحدد؟",
      archiveAll: "هل انت متأكد من ارشفة وإخفاء الإشعارات المحددة؟",
      wa_delete: "هل انت متأكد من حذف ترخيص واتساب؟",
    },
    nases: {
      delete_all:
        "هل انت متأكد من حذف الشبكات المحددة ؟ لايمكنك التراجع عن هذه العملية",
    },
    transactions: {
      no_debts: "تهانينا، الشبكة خالية من الديون",
    },
    bills: {
      delete:
        "هل انت متأكد من حذف الفاتورة المحددة ؟ لايمكنك التراجع عن هذه العملية.",
    },
  },
};
